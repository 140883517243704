<template>	
	<el-row  type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="16">
			<div class="tab9con">
			    <div class="tname">
			        <div class="td td1">#</div>
			        <div class="td td2">{{langs.TDDF}}（<template v-if="match_set.notes=='PAR'">{{langs.qgh}}</template><template v-if="match_set.notes=='HOLE'">{{langs.zdqn}}</template>{{match_set.num}}）</div>
					<div class="td td7" v-if="xianshilie.includes('Score')">{{langs.TotalPoints}}</div>
					<div class="td td3" v-if="xianshilie.includes('Rn')">{{langs.curround}}</div>
			        <div class="td td8" v-if="xianshilie.includes('Thru')">{{langs.fin}}</div>
					<template v-if="xianshilie.includes('Rx')">
			        	<div class="td td8" v-for="(item,index) in roundarr">{{langs['R'+item]}}</div>
					</template>
			        <div class="td td8" v-if="xianshilie.includes('Total')">{{langs.total}}</div>
			    </div>
				<template v-for="(team,index) in teams">
					<div class="trcon" @click="changeplayershow(index)">
						<div class="td td1">
							<!-- class="lf_change_pos span_red span_lv span_hui" -->
							<span class="lf_change_pos">
								<div class="lf_score_pos" v-if="team.tm_sts!=''">{{ team.tm_sts }}</div>
								<div class="lf_score_pos" v-else>{{team.teamsummary!=undefined?team.teamsummary.rank_code:""}}{{team.teamsummary!=undefined&&team.teamsummary.rank!=0?team.teamsummary.rank:""}}</div>
								<template v-if="rd_id>1">
									<img v-if="team.teamsummary!=undefined&&team.teamsummary.change>0" src="../../assets/images/GREEN.png" alt="">
									<img v-if="team.teamsummary!=undefined&&team.teamsummary.change<0" src="../../assets/images/RED.png" alt="">
									<img v-if="team.teamsummary!=undefined&&team.teamsummary.change==0" src="../../assets/images/dd.png" alt="">
									<!-- {{ team.teamsummary!=undefined?(team.teamsummary.change<0?Math.abs(team.teamsummary.change):(team.teamsummary.change==0?"":team.playerSummary.change)):"" }} -->
								</template>
							</span>
						</div>
						<div class="td td2">
							<div class='ui-link ui-linka lf_td_div lf_tm_df_tmname'>
								{{team.team_name}}
							</div>
						</div>
						<div class="td td7" v-if="xianshilie.includes('Score')">
							<div style="font-weight: bold;">
								{{team.teamsummary!=undefined?team.teamsummary.totalpoint:"-"}}
							</div>
						</div>
						<template v-if="xianshilie.includes('Rn')">
							<div class="td td3" style="font-weight: bold;" v-if="team.teamsummary!=undefined&&team.teamsummary.thru>=this.matchhole.hole.length" >
								{{team.teamsummary?team.teamsummary.point:'-'}}
							</div>
							<div class="td td3" style="font-weight: bold;" v-else >
								{{team.teamsummary?team.teamsummary.point:'-'}}
							</div>
						</template>
						<div class="td td8" v-if="xianshilie.includes('Thru')">
							{{team.teamsummary!=undefined?team.teamsummary.totalscore:""}}
						</div>
						<template v-if="xianshilie.includes('Rx')">
							<div class="td td8" style="font-weight: bold;" v-for="(rd,index) in roundarr">
								{{team.teamsummaryround!=undefined&&team.teamsummaryround[rd]!=undefined?team.teamsummaryround[rd].point:""}}
							</div>
						</template>
						<div class="td td8" v-if="xianshilie.includes('Total')">
							<div style="font-weight: bold;">
								{{team.teamsummary!=undefined?team.teamsummary.totalpoint:""}}
							</div>
						</div>
					</div>
					<div class="lf_tm_df_pl_box " v-if="playershow[index] == 0">
						<div class="lf_tm_df_pl_box_1">
							
						</div>
						<div class="lf_tm_df_pl_box_2" @click="changeplayershow(index)">
							<div v-for="(item,index) in team.teamplayer" class="trcon">
								<div class="td td2" style="position: relative;">
									<span v-if="match_set.notes=='PAR'&&team.teamsummary&&team.teamsummary.plids.indexOf(item.pl_id)!=-1" class="lf_tip_w_box_l" style="height: 0.8rem;"></span>
									<div target="top" class='ui-link ui-linka lf_td_div'>
										<div v-if="match_set.mt_country_show_type==1" class="lf_country_new">
											<img style="vertical-align: middle" :src="'http://overview.wifigolf.com/images/flags/changurl.php?mt_id='+mt_id+'&content=64/'+ (item.player!=undefined&&item.player.pl_country?item.player.pl_country:'country')+'.png'"/>
										</div>
										<div v-if="match_set.mt_country_show_type==2" class="lf_country_new">
											<text>{{item.player!=undefined&&item.player.pl_country?item.player.pl_country:''}}</text>
										</div>
										<div class="lf_player_name_divs">
											<template v-if="match_set.xueyuan_show==1">
												<div class="lf_club_name">{{ item.player?item.player.xueyuan:"" }}</div>
											</template>
											<div class="lf_player_name_div">{{ item.player!=undefined?item.player.pl_cn_name:"" }}{{ item.groupDetail!=undefined&&item.groupDetail.gp_start_hole>9&&item.groupDetail.gp_start_hole<18?'*':'' }}{{ match_set.mt_amateur=='(A)'&&item.player&&item.player.pl_type=='AMA'?'(A)':'' }}</div>
											<div class="lf_imgs_type">
												<div class="lf_backg_color_img" style="max-width: 0.8rem;height: 0.6rem;background-color: unset;width: auto;" v-if="item.matchPlayer!=undefined&&item.matchPlayer.mp_logo!=''&&item.matchPlayer.mp_logo!=null">
													<img style="max-width: 0.8rem;height: 0.6rem;width: auto;" :src="'http://www.wifigolf.com/club/adm/images/matches/'+mt_id+'/'+item.matchPlayer.mp_logo" alt="">
												</div>
												<div v-if="match_set.tee_show_type==1" class="lf_backg_color_img" style="color: #333; font-size: 0.14rem;font-weight: bold;">
													{{ item.matchPlayer!=undefined?item.matchPlayer.mp_teename.split("")[0]:"" }}
												</div>
												<div class="lf_backg_color_img" v-if="((match_set.mt_sex_show_type=='male'||match_set.mt_sex_show_type=='both')&&(item.player!=undefined&&item.player.pl_sex==1))||((match_set.mt_sex_show_type=='female'||match_set.mt_sex_show_type=='both')&&(item.player!=undefined&&item.player.pl_sex==0))">
													<img v-if="(match_set.mt_sex_show_type=='male'||match_set.mt_sex_show_type=='both')&&(item.player!=undefined&&item.player.pl_sex==1)" src="../../assets/images/u7.png" alt="">
													<img v-if="(match_set.mt_sex_show_type=='female'||match_set.mt_sex_show_type=='both')&&(item.player!=undefined&&item.player.pl_sex==0)" src="../../assets/images/u9.png" alt="">
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="td td7" v-if="xianshilie.includes('Score')">
									<div>
										{{item.playerSummary!=undefined&&item.playerSummary.jstotalpoint!=null?item.playerSummary.jstotalpoint:"-"}}
									</div>
								</div>
								<template v-if="xianshilie.includes('Rn')">
									<div class="td td3">
										{{ item.playerSummary&&item.playerSummary.jspoint!=null?item.playerSummary.jspoint:"-" }}
									</div>
								</template>
								<div class="td td8" v-if="xianshilie.includes('Thru')">
									<span class="span_hei" :class="item.playerSummary!=undefined&&item.playerSummary.sts=='C'?'span_lv':''">{{item.playerSummary!=undefined&&item.playerSummary.thru!=0?(item.playerSummary.thru>=this.matchhole.hole.length?"F":item.playerSummary.thru):(item.groupDetail!=undefined&&item.groupDetail.gp_start_time!=undefined&&item.matchPlayer.mp_sts==""&&item.groupDetail.gp_start_time!="00:00"?item.groupDetail.gp_start_time:"")}}</span>
								</div>
								<template v-if="xianshilie.includes('Rx')">
									<div class="td td8" v-for="(rd,index) in roundarr">
										{{item.playerSummaryround!=undefined&&item.playerSummaryround[rd]!=undefined?item.playerSummaryround[rd].point:""}}
									</div>
								</template>
								<div class="td td8" v-if="xianshilie.includes('Total')">
									<div>
										{{item.playerSummary!=undefined?item.playerSummary.totalpoint:""}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="lf_tm_df_zk_box" v-if="playershow[index] == 1">
						<div class="lf_tm_df_zk_box_tit">
							<div>
								{{langs.TDDF}}
								<text>（<template v-if="match_set.notes=='PAR'">{{langs.qgh}}</template><template v-if="match_set.notes=='HOLE'">{{langs.zdqn}}</template>{{match_set.num}}）</text>
							</div>
							<div class="lf_tm_df_zk_box_tit_close" @click="changeplayershow(index)"><img src="../../assets/images/u2959.png" alt=""></div>
						</div>
						<div class="lf_tm_df_zk_box_slider_box" :style="'height:'+(1.5+(1.1*team.teamplayer.length))+'rem;overflow: visible;'">
							<el-carousel :style="'height:'+(1.5+(1.1*team.teamplayer.length))+'rem;'"   
							    @touchstart.native="handleTouchStart"
								@touchmove.native="handleTouchMove"
								@touchend.native="handleTouchEnd"
								:loop="false"
							    arrow="always"
							    indicator-position="inside"
								:autoplay="false"
								ref="carousel"
								>
							    <el-carousel-item v-for="area in 2" :key="item">
							      	<div class="lf_per_score_box" style="width: 6.7rem; position: relative;">
							          	<div class="lf_per_score_box_tit" style="padding-left: 0.2rem;">
											<div>HOLE</div>
											<template v-for="(item, index) in 9">
												<div>{{ item+(area-1)*9 }}</div>
											</template>
											<div>{{area==1?"OUT":(area==2?"IN":"C")}}</div>
										</div>
										<div class="lf_per_score_box_par" style="text-indent: 0.2rem;">
											<div style="width: 1.6rem;">Par</div>
											<template v-for="(item, index) in 9">
												<div style="text-indent: 0;">{{ matchhole.hole[item+(area-1)*9-1].par }}</div>
											</template>
											<div style="text-indent: 0;">{{ area==1?matchhole.out:matchhole.in }}</div>
										</div>
										<template v-for="(item, index) in team.teamplayer">
										<div class="lf_per_score_box_score" :style="index%2==1?'background-color: #F3F4FC;':''" style="padding-left: 0.2rem;">
											<span v-if="match_set.notes=='PAR'&&team.teamsummary&&team.teamsummary.plids.indexOf(item.pl_id)!=-1" class="lf_tip_w_box_l"></span>
											<div>{{ item.player!=undefined?item.player.pl_cn_name:"" }}</div>
											<template v-for="(hole, holeindex) in 9">
												<div style="padding: 5px 0">
													<div>
														{{item.playerScore&&item.playerScore[hole+(area-1)*9-1]?(item.playerScore[hole+(area-1)*9-1].score==31?"/":item.playerScore[hole+(area-1)*9-1].score):""}}
													</div>
												</div>
											</template>
											<div style="color: #333; font-weight: 700">{{ item.playerSummary?item.playerSummary[area==1?"out":"in"]:"" }}</div>
										</div>
										<div class="lf_per_score_box_score_point" :style="index%2==1?'background-color: #F3F4FC;':''" style="padding:0 0.2rem;">
											<div style="justify-content: center;">{{ langs["Points"] }}</div>
											<template v-for="(hole, holeindex) in 9">
												<div>
													{{item.playerScore&&item.playerScore[hole+(area-1)*9-1]?item.playerScore[hole+(area-1)*9-1].point:""}}
													<div class="lf_tm_df_pl_box_b" v-if="team&&team.teamscore&&team.teamscore[hole+(area-1)*9]&&team.teamscore[hole+(area-1)*9].plids.indexOf(item.pl_id)!=-1"></div>
												</div>
											</template>
											<div>{{ item.playerSummary?item.playerSummary[area==1?"outpoint":"inpoint"]:"" }}</div>
										</div>
										</template>
							      </div>
							    </el-carousel-item>
						    </el-carousel>
						</div>
					</div>
					<Ad :match_set="match_set" v-if="index%10==9&&index!=list.length-1"></Ad>
				</template>
				<!-- <Ad :match_set="match_set"></Ad> -->
			</div>
		</el-col>
	</el-row>
</template>

<script>
 import {
        getSystemLang,
        getLangAll
    } from '../../lang';
	import Ad from "../Ad.vue";
import axios from '../../axios/request';
    export default {
		data() {
		    return {
		        langs: '',
		        systemlang: 'cn',
                outpar:'',
				inpar:'',
				mt_id:'',
				showjinji:-1,
				xianshilie: [],
				roundarr: [],
				playershow: [],
				startX: 0,
				endX: 0,
				isTouchMove: false
		    }
		},
        name: 'Grbg',
        props: {
			list:Object,
			teams:Object,
			playoff:Object,
			match:Object,
			match_set:Object,
			matchhole:Object,
			modal:String,
			rd_id:String,
			
        },
		components: {
			Ad
        },
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			var deviceWidth = document.documentElement.clientWidth || window.innerWidth
			if(deviceWidth>=768){
				this.WebModel=true;
			}else{
				this.WebModel=false;
			}
			var params = this.$route.query;
			var mt_id = "";
            if(this.getQueryVariable("MatchID")===false){
                if(this.getQueryVariable("mtid")===false){
                    mt_id = 31934;
                }else{
                    mt_id = this.getQueryVariable("mtid");
                }
            }else{
                mt_id = this.getQueryVariable("MatchID");
            }
			this.mt_id = mt_id;
			for(var k in this.list){
				if(this.list[k].matchPlayer.mp_sts=="MC"){
					this.showjinji = k;
					break;
				}
			}
			var roundarr = [];
			for(var i=1;i<=this.match.mt_round_num;i++){
				roundarr.push(i)
			}
			this.roundarr = roundarr;
			if(this.match.club_id!=undefined&&this.match.tour_id!=undefined){
				axios('score/mobilescoreshow.json',{
					club_id: this.match.club_id,
					tour_id: this.match.tour_id,
					pagename: "TMDF42"
				})
				.then((res)=>{
					var xianshiliestr = res.data.data.data.xianshilie;
					this.xianshilie = xianshiliestr.split(",");
				})
			}
			this.setplayershow();
			this.setmatchholesum();
		},
		watch:{
			teams:function(nval,oval){
				this.setplayershow();
			},
			match:function(nval,oval){
				var roundarr = [];
				for(var i=1;i<=nval.mt_round_num;i++){
					roundarr.push(i)
				}
				this.roundarr = roundarr;
				axios('score/mobilescoreshow.json',{
					club_id: nval.club_id,
					tour_id: nval.tour_id,
					pagename: "TMDF42"
				})
				.then((res)=>{
					var xianshiliestr = res.data.data.data.xianshilie;
					this.xianshilie = xianshiliestr.split(",");
				})
			},
			matchmode:function(nval,oval){
				axios('score/mobilescoreshow.json',{
					club_id: this.match.club_id,
					tour_id: this.match.tour_id,
					pagename: "TMDF42"
				})
				.then((res)=>{
					var xianshiliestr = res.data.data.data.xianshilie;
					this.xianshilie = xianshiliestr.split(",");
				})
			},
			matchhole:function(nval,oval){
				this.setmatchholesum();
			}
		},
		methods:{
			changeplayershow(index){
				var status = this.playershow[index];
				for(var key in this.playershow){
					this.playershow[key] = 0;
				}
				this.playershow[index] = 1-status;
			},
			setmatchholesum(){
				this.matchhole.out = 0;
				this.matchhole.in = 0;
				this.matchhole.c = 0;
				for(var key in this.matchhole.hole){
					if(this.matchhole.hole[key].ho_id<=9){
						this.matchhole.out += this.matchhole.hole[key].par;
					}else if(this.matchhole.hole[key].ho_id<=18&&this.matchhole.hole[key].ho_id>=10){
						this.matchhole.in += this.matchhole.hole[key].par;
					}else{
						this.matchhole.c += this.matchhole.hole[key].par;
					}
				}
			},
			setplayershow(){
				for(var key in this.teams){
					this.playershow[key] = 0;
				}
			},
			gotoplayerscore(pl_id){
				var params = this.$route.query;
				var playerparams = {};
				playerparams["lang"] = params["lang"];
				playerparams["mtid"] = this.mt_id
				playerparams["plid"] = pl_id;
				playerparams["mt_round"] = this.rd_id;
				let routeUrl = this.$router.resolve({
					path: "/playerscore",
					query: playerparams
				});
				window.open(routeUrl.href, '_self');
			},
			getQueryVariable(variable)
			{
				var query = window.location.search.substring(1);
				var vars = query.split("&");
                var returnval = "";
				for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    if(pair[0] == variable){returnval = pair[1];}
				}
                if(returnval!=""){
                    return returnval;
                }
				return(false);
			},
			handleTouchStart(e) {
				console.log(e)
			  this.startX = e.touches[0].clientX;
			},
			handleTouchMove(e) {
			  this.endX = e.touches[0].clientX;
			  this.isTouchMove = true;
			},
			handleTouchEnd(e) {
			  if (this.isTouchMove) {
				const distance = this.endX - this.startX;
				if (distance > 10) { // 向左滑动
				  this.$refs.carousel[0].prev();
				} else if (distance < -10) { // 向右滑动
				  this.$refs.carousel[0].next();
				}
				this.isTouchMove = false;
			  }
			}
			
		}
    }
</script>

<style scoped="scoped">
	.tab9con .tname,.tab9con .trcon{
		display: flex;
		align-items: center;
	}
	.tab9con .tname .td,.tab9con .trcon .td{
		text-align:center; 
		font-size:0.28rem;
		line-height:0.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-right: 1px solid #E3E3E3;
		flex-shrink: 0;
	}
	.tab9con .tname .td,.tab9con .trcon .td:nth-last-of-type(1){
		border-right: unset;
	}
	.tab9con .tname .td2,.tab9con .trcon .td2{
		display: flex;
		align-items: center;
		flex-grow: 1;
	}
	.tab9con .tname .td3,.tab9con .trcon .td3{
		width: 0.7rem;
	}
	.tab9con .tname .td7,.tab9con .trcon .td7{
		width: 0.93rem;
		line-height: 0.3rem;
	}
	.tab9con .tname .td8,.tab9con .trcon .td8{
		width: 0.8rem;
	}
	.tab9con .tname{
		border-left: 1px solid #025287;
	}
	.tab9con .tname .td{
		background:#025287; 
		color:#fff;
		padding: 0;
		font-size: 0.22rem;
		box-sizing: border-box;
		font-weight: bold;
		border-right: 1px solid #fff;
	}
	.tab9con .tname .td:nth-last-of-type(1){
		border-right: none;
	}
	.tab9con .trcon{
		background:#f7f7f7;
		position: relative;
		border: 1px solid #E3E3E3;
		border-top: unset;
		box-sizing: border-box;
	}
	.tab9con .trcon:nth-of-type(2n){background:#fff;}
	.tab9con{
		width: 7rem;
		margin: 0.1rem auto 0.2rem;
		border-top-left-radius: 0.1rem;
		border-top-right-radius: 0.1rem;
		overflow: hidden;
	}
	
	
	.tab9con .trcon .td1{position: relative;}
	
	.tab9con .trcon .td7 .lf_e{width: 0.68rem;height: 0.56rem; background-color: rgba(183, 42, 71, 1);border-radius: 0.2rem;font-family: '.萍方-简 中粗体', '.萍方-简 正规体', '.萍方-简';font-weight: 600;font-style: normal;font-size: 0.24rem;color: #FFFFFF;display: flex;align-items: center;justify-content: center;margin: 0 auto;}
	.tab9con .trcon .td7 .lf_p{width: 0.68rem;height: 0.56rem; background-color: rgba(85, 86, 91, 1);border-radius: 0.2rem;font-family: '.萍方-简 中粗体', '.萍方-简 正规体', '.萍方-简';font-weight: 600;font-style: normal;font-size: 0.24rem;color: #FFFFFF;display: flex;align-items: center;justify-content: center;margin: 0 auto;}
	.tab9con .trcon .td7 .lf_b{width: 0.68rem;height: 0.56rem; background-color: rgba(2, 82, 135, 1);border-radius: 0.2rem;font-family: '.萍方-简 中粗体', '.萍方-简 正规体', '.萍方-简';font-weight: 600;font-style: normal;font-size: 0.24rem;color: #FFFFFF;display: flex;align-items: center;justify-content: center;margin: 0 auto;}
	
	
	
	.tab9con .trcon .td2 .lf_td_div{
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
	}
	.tab9con .trcon .td2 .lf_country_new{
		width: 0.56rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		font-style: italic;
	}
	.tab9con .trcon .td2 .lf_country_new text{
		font-size: 0.18rem!important;
	}
	.tab9con .trcon .td2 .lf_country_new img{
		width: 0.3rem;height: 0.2rem;
	}
	.tab9con .trcon .td2 .lf_player_name_divs{
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		color: #333;
		font-weight: bold;
		font-size: 0.28rem;
		padding-left: 0.1rem;
	}
	.lf_club_name{
		position: absolute;
		bottom: 0;
		left: 0;
		font-size: 0.18rem;
		color: #444;
		width: 100%;
		height: 0.2rem;
		overflow: hidden;
		line-height: 0.2rem;
		text-align: left;
		font-weight: 100;
		padding-left: 0.1rem;
	}
	.lf_imgs_type{
		display: flex;
		align-items: center;
	}
	.lf_zanzhulogo{
		width: 0.8rem;
		height: 0.6rem;
	}
	.lf_backg_color_img{
		width: 0.24rem;
		height: 0.24rem;
		background-color: #EEEEEE;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 0.1rem;
	}
	.lf_backg_color_img img{
		width: 0.16rem;
		height: 0.16rem;
	}
	.tab9con .jjx{
		height:0.3rem;
		background:#FF5860; 
		width:100%; 
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.tab9con .ycjjx{
		height:0.3rem;
		background:#025287; 
		width:100%; 
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.lf_change_pos{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_change_pos img{
		width: 0.05rem;
		height: 0.12rem;
	}
	.span_red{color:#f00;}
	.span_lan{color:#005288;}
	.span_hui{color:#9c9c9c;}
	.span_hei{color:#333;}
	.span_lv{color:#1fbf41;}
	
	
	.lf_tm_df_tmname{
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 0.28rem;
		color: #025287;
		padding-left: 0.1rem;
		line-height: 0.3rem;
		flex-shrink: 0;
		width: 100px!important;
		flex-grow: 1;
		flex-shrink: 0;
		justify-content: flex-start;
		overflow: hidden;
		text-align: left;
	}
	.lf_tm_df_pl_box{
		display: flex;
	}
	.lf_tm_df_pl_box_1{
		width: 0.66rem;
		background-color: #ffff;
		border-left: 1px solid #E3E3E3;
		border-bottom: 1px solid #E3E3E3;
		position: relative;
		overflow: unset;
	}
	.lf_tm_df_pl_box_2{
		flex-grow: 1;
		flex-direction: column;
	}
	.lf_tm_df_zk_box_tit{
		height: 0.55rem;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-size: 0.24rem;
		text-decoration: none;
		color: #025287;
		border-top:2px solid #555555;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.lf_tm_df_zk_box_tit div{
		margin-left: 0.3rem;
	}
	.lf_tm_df_zk_box_tit text{
		font-family: '微软雅黑';
		font-weight: 400;
		text-decoration: none;
	}
	.lf_tm_df_zk_box_tit_close{
		width: 0.5rem;
		height: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 0.2rem;
	}
	.lf_tm_df_zk_box_tit_close img{
		width: 0.5rem;
		height: 0.5rem;
	}
	.lf_per_score_box {
		margin: 0 auto;
		border-bottom: 1px solid #e3e3e3 ;
	}
	.lf_per_score_box_tit {
	    display: flex;
	    height: 0.4rem;
	    background-color: #025287;
	    font-size: 0.24rem;
	    color: #fff;
	    font-weight: bold;
	    border-bottom-left-radius: 0;
	    border-bottom-right-radius: 0;
	}
	.lf_per_score_box_tit div {
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    border-left: 1px solid #fff;
	    box-sizing: border-box;
	}
	.lf_per_score_box_tit div:nth-of-type(1) {
	    border: none;
	}
	.lf_per_score_box_par {
	    display: flex;
	    height: 0.4rem;
	    background-color: #fff;
	    font-size: 0.24rem;
	    color: #aaa;
	    font-weight: bold;
	}
	.lf_per_score_box_par div {
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    border-left: 1px solid #e3e3e3;
	    box-sizing: border-box;
	    color: #aaa;
	    border-bottom: 1px solid #e3e3e3 !important;
	}
	.lf_per_score_box_par div:nth-of-type(1) {
	    border: none;
	    color: #aaa;
	}
	.lf_per_score_box_score_point{
		border-top: 1px solid #e3e3e3;
		border-left:1px solid #e3e3e3 ;
		border-right: 1px solid #e3e3e3 ;
	}
	.lf_per_score_box_score_point {
	    display: flex;
	    height: 0.5rem;
	    background-color: #fff;
	    font-size: 0.24rem;
	    color: #999;
	}
	.lf_per_score_box_score_point div {
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    border-left: 1px solid #e3e3e3;
	    box-sizing: border-box;
		color: #999;
		position: relative;
	}
	.lf_tm_df_pl_box_b{
		width: 0.49rem!important;
		height: 0.06rem;
		background: inherit;
		background-color: rgba(2, 82, 135, 1);
		border: none;
		border-radius: 0.03rem;
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		position: absolute!important;
		bottom: 0;
		left: 0;
	}
	.lf_per_score_box_score_point div:nth-of-type(1) {
	    border: none;
	    color: #999;
	}
	.lf_tip_w_box_l{
		width: 0.06rem!important;
		height: 1.1rem;
		background: inherit;
		background-color: rgba(2, 82, 135, 1);
		border: none;
		border-radius: 0.03rem;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		position: absolute;
		top: 0;
		left: 0;
	}
	.lf_per_score_box_score {
	    display: flex;
	    height: 0.6rem;
	    background-color: #fff;
	    font-size: 0.24rem;
	    color: #fff;
		position: relative;
		border-top: 1px solid #e3e3e3;
		border-left:1px solid #e3e3e3 ;
		border-right: 1px solid #e3e3e3 ;
	}
	.lf_per_score_box_score div {
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    border-left: 1px solid #e3e3e3;
	    box-sizing: border-box;
		color: #000;
	}
	.lf_per_score_box_score div:nth-of-type(1) {
	    border: none;
	    color: #000;
	}
	.lf_per_score_box_score div,
	.lf_per_score_box_par div,
	.lf_per_score_box_tit div,
	.lf_per_score_box_score_point div{
	    width: 0.5rem;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    flex-shrink: 0;
	}
	.lf_per_score_box_score div:nth-of-type(1),
	.lf_per_score_box_par div:nth-of-type(1),
	.lf_per_score_box_tit div:nth-of-type(1) ,
	.lf_per_score_box_score_point div:nth-of-type(1){
	    width: 1.4rem;
	    display: flex;
	    align-items: center;
	    flex-shrink: 0;
		justify-content: flex-start;
	}
	.lf_per_score_box_score div:nth-last-of-type(1),
	.lf_per_score_box_par div:nth-last-of-type(1),
	.lf_per_score_box_tit div:nth-last-of-type(1),
	.lf_per_score_box_score_point div:nth-last-of-type(1){
	    width: 0.6rem;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    flex-shrink: 0;
	}
	.lf_per_score_box_score div .lf_score_box_box {
	    width: 0.5rem;
	    height: 0.5rem;
	    color: #fff;
	    border-radius: 50%;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    font-weight: 700;
	    flex-shrink: 0;
	}
	.lf_per_score_box_score .lf_eagle {
	    background: rgba(227, 177, 2, 1);
	}
	.lf_per_score_box_score .lf_birdie {
	    background: rgba(183, 42, 71, 1);
	}
	.lf_per_score_box_score .lf_par {
	    background: rgba(83, 86, 91, 1);
	}
	.lf_per_score_box_score .lf_borgey {
	    background: rgba(2, 82, 135, 1);
	}
	.lf_per_score_box_score .lf_doubleborgey {
	    background: rgba(88, 8, 195, 1);
	}
	
	@media screen and (max-width: 768px) {
		
	}
</style>
